import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCompaniesFromApi, fetchPlans } from '../services/api';
import { analyzeMultiCompanyCSVThunk, uploadMultiCompanyCSVThunk } from '../slices/companySlice';
import { AuthContext } from '../utils/AuthProvider';
import { processMultiCompanyFile, processMultiCompanyFileWithoutCleaning } from '../utils/csvUtil';
import { showErrorToast, showSuccessToast } from '../utils/toastUtil';

const MultiCompanyUploadButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [errors, setErrors] = useState([]);
  const [useFileCleaning, setUseFileCleaning] = useState(true);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { getIdToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companies = await fetchCompaniesFromApi();
        const plans = await fetchPlans();
        setAllCompanies(companies);
        setAllPlans(plans);
      } catch (error) {
        showErrorToast('Error fetching company and plan data');
      }
    };
    fetchData();
  }, []);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      setErrors([]);
      try {
        const token = await getIdToken();

        // Use the appropriate processing function based on the toggle state
        const processFunction = useFileCleaning
          ? processMultiCompanyFile
          : processMultiCompanyFileWithoutCleaning;

        processFunction(
          file,
          allCompanies,
          allPlans,
          async (processedData) => {
            setErrors(processedData.errors);

            if (processedData.validatedData.length === 0) {
              showErrorToast('No valid data found in the uploaded file');
              setIsLoading(false);
              return;
            }

            const action = await dispatch(analyzeMultiCompanyCSVThunk({
              parsedData: processedData.validatedData,
              newCompanies: processedData.newCompanies,
              token
            }));

            if (analyzeMultiCompanyCSVThunk.fulfilled.match(action)) {
              setAnalysisResults(action.payload);
            } else {
              showErrorToast('Error analyzing multi-company CSV!');
            }
            setIsLoading(false);
          },
          (error) => {
            showErrorToast(error.message || 'Error processing multi-company CSV!');
            setIsLoading(false);
          }
        );
      } catch (error) {
        showErrorToast(error.message || 'Unexpected error during file upload');
        setIsLoading(false);
      }
    }
  };

  const handleClear = () => {
    setAnalysisResults(null);
    setErrors([]);
    setActiveTab(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFinalSubmit = async () => {
    setIsLoading(true);
    try {
      const token = await getIdToken();
      const action = await dispatch(uploadMultiCompanyCSVThunk({
        changesByCompany: analysisResults.changesByCompany,
        newCompanies: analysisResults.newCompanies,
        token
      }));

      if (uploadMultiCompanyCSVThunk.fulfilled.match(action)) {
        showSuccessToast('Multi-company upload successful');
        setIsOpen(false);
        setAnalysisResults(null);
      } else {
        showErrorToast('Error processing multi-company CSV!');
      }
    } catch (error) {
      showErrorToast('Error processing multi-company CSV!');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleFileCleaning = (event) => {
    setUseFileCleaning(event.target.checked);
  };

  // Rest of your component remains the same
  const renderCompanyChanges = (companyData, companyId, isNewCompany) => (
    <Accordion
      key={companyId}
      sx={{
        marginBottom: 2,
        border: 1,
        borderColor: isNewCompany ? 'success.dark' : 'primary.dark',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: isNewCompany ? 'success.light' : 'warning.main',
          '&:hover': {
            backgroundColor: isNewCompany ? 'success.main' : 'warning.light',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Typography sx={{ flexGrow: 1, fontWeight: 'bold' }}>
            {companyData.companyName} (ID: {companyId})
          </Typography>
          <Box>
            <Chip
              icon={<AddIcon />}
              label={companyData.adds.length}
              size="small"
              color="success"
              sx={{ marginRight: 1 }}
            />
            <Chip
              icon={<EditIcon />}
              label={companyData.edits.length}
              size="small"
              color="warning"
              sx={{ marginRight: 1 }}
            />
            <Chip
              icon={<BlockIcon />}
              label={companyData.disables.length}
              size="small"
              color="error"
            />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          <ListItem>
            <ListItemText primary={`New Employees: ${companyData.adds.length}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Edited Employees: ${companyData.edits.length}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Disabled Employees: ${companyData.disables.length}`} />
          </ListItem>
        </List>
        <Divider />
        {companyData.adds.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ color: 'success.main', fontWeight: 'bold' }}>New Employees:</Typography>
            <List>
              {companyData.adds.map((employee, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${employee.firstName} ${employee.lastName}`}
                    secondary={employee.email}
                    sx={{ '& .MuiListItemText-primary': { fontWeight: 'bold' } }}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
        {companyData.edits.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ color: 'warning.main', fontWeight: 'bold' }}>Edited Employees:</Typography>
            <List>
              {companyData.edits.map((edit, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${edit.currentData.firstName} ${edit.currentData.lastName}`}
                    secondary={Object.entries(edit.changes).map(([field, { oldValue, newValue }]) =>
                      `${field}: ${oldValue} → ${newValue}`
                    ).join(', ')}
                    sx={{ '& .MuiListItemText-primary': { fontWeight: 'bold' } }}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
        {companyData.disables.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ color: 'error.main', fontWeight: 'bold' }}>Disabled Employees:</Typography>
            <List>
              {companyData.disables.map((disable, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${disable.employee.firstName} ${disable.employee.lastName}`}
                    secondary={disable.employee.email}
                    sx={{ '& .MuiListItemText-primary': { fontWeight: 'bold' } }}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );

  return (
    <>
      <StyledUploadCSVButton
        startIcon={<CloudUploadIcon />}
        onClick={() => setIsOpen(true)}
        disabled={allCompanies.length === 0 || allPlans.length === 0}
      >
        Multi Company Upload
      </StyledUploadCSVButton>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Upload Multiple Company Users</DialogTitle>
        <DialogContent>
          {!analysisResults ? (
            <>
              <DialogContentText>
                Upload a CSV file containing user data for multiple companies.
              </DialogContentText>
              <FormControlLabel
                control={
                  <Switch
                    checked={useFileCleaning}
                    onChange={toggleFileCleaning}
                    color="primary"
                  />
                }
                label="Use file cleaning service before processing"
                sx={{ marginTop: 2, marginBottom: 2, display: 'block' }}
              />
              <input
                ref={fileInputRef}
                type="file"
                accept=".csv,.xlsx,.xls"
                onChange={handleUpload}
                style={{
                  marginTop: '20px',
                  display: 'block',
                  width: '100%',
                }}
              />
              {errors.length > 0 && (
                <Box sx={{ marginTop: 2, backgroundColor: 'error.light', padding: 2, borderRadius: 1 }}>
                  <Typography variant="h6" color="error">Errors:</Typography>
                  <List>
                    {errors.map((error, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={`Row ${error.row}: ${error.error}`} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </>
          ) : (
            <>
              <DialogContentText>
                Review the changes before final submission:
              </DialogContentText>
              <Tabs
                value={activeTab}
                onChange={(event, newValue) => setActiveTab(newValue)}
                aria-label="company changes tabs"
                sx={{ marginBottom: 2 }}
              >
                <Tab label="New Companies" />
                <Tab label="Existing Companies" />
              </Tabs>
              {activeTab === 0 && (
                <Box>
                  <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2, color: 'success.main' }}>New Companies:</Typography>
                  {analysisResults.newCompanies.map(company => renderCompanyChanges(analysisResults.changesByCompany[company.companyId], company.companyId, true))}
                </Box>
              )}
              {activeTab === 1 && (
                <Box>
                  <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2, color: 'primary.main' }}>Existing Companies:</Typography>
                  {Object.entries(analysisResults.changesByCompany)
                    .filter(([companyId]) => !analysisResults.newCompanies.some(nc => nc.companyId.toString() === companyId))
                    .map(([companyId, companyData]) => renderCompanyChanges(companyData, companyId, false))}
                </Box>
              )}
            </>
          )}
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
              <CircularProgress />
              <Typography variant="body2" sx={{ marginLeft: 2 }}>
                {useFileCleaning ? 'Cleaning and processing file...' : 'Processing file...'}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClear} disabled={isLoading} startIcon={<ClearIcon />}>
            Clear
          </Button>
          <Button onClick={() => setIsOpen(false)} disabled={isLoading}>
            Cancel
          </Button>
          {analysisResults && (
            <Button onClick={handleFinalSubmit} disabled={isLoading} color="primary" variant="contained">
              Confirm and Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MultiCompanyUploadButton;

const StyledUploadCSVButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));